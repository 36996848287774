import React, { useContext, useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";

import PageTitle from "../../components/PageTitle";
import BlogList from "../../components/BlogList";
import DefaultPage from "../DefaultPage";
import { CompanyContext } from "../../contexts/CompanyContext";
import { LeadContext } from "../../contexts/LeadContext";
import useTraceId from "../../hooks/useTraceId";
import { Helmet } from "react-helmet";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";

const CarBookingPage = () => {
  // const { t } = useTranslation();

  const {companyData} = useContext(CompanyContext);
  const { traceData } = useContext(LeadContext);
  const trace = useTraceId();

  const [pageTitle, setPageTitle] = useState("");
  const [lastPageTitle, setLastPageTitle] = useState(document.title);

  useEffect(() => {
    setPageTitle(document.title);
  }, [document.title]);

  useEffect(() => {
    if(companyData.id && pageTitle !== lastPageTitle) {
      if (trace.traceId) {
        trace.setTraceParams({
          ...traceData,
          trid: trace.traceId,
          crm_lead_id: trace.leadId,
          dominio: `${window.location.origin}`,
          local: `${window.location.pathname}`,
          title: `${document.title}`,
          tipo: null,
        });
      }
    }
  }, [trace.traceId, trace.leadId, companyData]);

  return (<>
    <Helmet>
      <title>Nossos Artigos</title>
      <meta name="description" content={`Artigos escrito por${companyData?.seo_title ? companyData.seo_title : companyData.nome}`}/>
      <meta name="og:description" content={`Artigos escrito por ${companyData?.seo_title ? companyData.seo_title : companyData.nome}`}/>
      <meta property="og:title" content={`Nossos Artigos`}/>
      <meta property="og:site_name" content={`Nossos Artigos`}/>
      <meta property="og:url" content={`${window.location.host}${window.location.pathname}`}/>
      <meta property="og:image" content={`${companyData?.logo}`}/>
      <meta name="keywords" content={companyData?.seo_tags}/>
    </Helmet>
    <DefaultPage>
      {/* <Header /> */}
      <PageTitle
        pageTitle={'Nossos Artigos'}
        // pagesub={t("header-navigation.blog")}
        // pageTitle={t("header-navigation.blog")}
        // pagesub={t("header-navigation.blog")}
      />
      <BlogList />
      {/* <Footer /> */}
    </DefaultPage>
  </>
  );
};
export default CarBookingPage;
