import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
// import {
//   DatePickerComponent,
//   TimePickerComponent,
// } from "@syncfusion/ej2-react-calendars";
// import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaStar,
  FaStarHalfAlt,
  FaCar,
  FaCogs,
  FaTachometerAlt,
  FaEmpire,
  FaDesktop,
  FaKey,
  FaLock,
  FaEye,
  FaCarSide,
  FaRegKeyboard,
  FaAppStore,
  FaCalendar,
  FaTools,
  FaPaintRoller,
  FaCheckCircle  
} from "react-icons/fa";
import { BsFillFunnelFill } from "react-icons/bs";
import { GiCarDoor } from "react-icons/gi";
import { MdCategory } from "react-icons/md";
import InputMask from "react-input-mask";

// import img1 from "../../img/booking.jpg";
// import img2 from "../../img/master-card.jpg";
// import img3 from "../../img/paypal.jpg";

import "./style.css";
import { getSpecificVehicle } from "../../services/vehicles/requests";
import Slider from "react-slick";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { brandOptions, colorOptions, doorCount, endPlateOptions, engineOptions, fuelOptions, motorOptions, optionalsOptions, steeringOptions, vehicleTypeOptions, conservationOptions, documentationOptions } from "../../utils/dataForVehicles";
import { phoneMask } from './../../utils/maskForPhone';
import { WhatsAppContext } from './../../contexts/WhatsappContext';
import { CompanyContext } from "../../contexts/CompanyContext";
import PageTitle from "../PageTitle";
import { LeadData } from './../../contexts/leadData';
import useTraceId from '../../hooks/useTraceId';
import { createLead } from "../../services/lead/requests";
import Swal from 'sweetalert2';
import { Helmet } from "react-helmet";
import { LeadContext } from './../../contexts/LeadContext';
import LogoRp from '../../img/logo-rp.png'
import { capitalizeFirstLetter } from './../../utils/capitalizeFirstLetter';
import { logViewed } from "../../services/logs/requests";

const CarBooking = () => {
  // const { t } = useTranslation();
  const {companyData} = useContext(CompanyContext);
  const { changeData } = useContext(WhatsAppContext);
  const [loading, setLoading] = useState(false);
  const [vehicle, setVehicle] = useState({});
  const [data, setData] = useState({nome: "", email: "", cpf: "", celular: ""})
  const { slug } = useParams();
  const trace = useTraceId();
  const { traceData } = useContext(LeadContext);

  const [pageTitle, setPageTitle] = useState("");
  const [lastPageTitle, setLastPageTitle] = useState(document.title);

  useEffect(() => {
    if(vehicle.id){
      registerLog()
    }
  },[vehicle])

  useEffect(() => {
    setPageTitle(document.title);
  }, [document.title]);

  useEffect(() => {
    if(vehicle.id && companyData.id) {
      changeData({
        phone: companyData?.whatsapp_number,
        message: `Gostaria de saber mais sobre o veículo ${vehicle.title}.`
      })
    }
    if(localStorage.getItem('___LeadID')){
      setData({nome: localStorage.getItem('___LeadName'), email: localStorage.getItem('___LeadEmail'), celular: localStorage.getItem('___LeadMobile')})
    }
  },[vehicle, companyData])

  useEffect(() => {
    if(vehicle.id && pageTitle !== lastPageTitle) {
      if (trace.traceId) {
        trace.setTraceParams({
          ...traceData,
          trid: trace.traceId,
          crm_lead_id: trace.leadId,
          dominio: `${window.location.origin}`,
          local: `${window.location.pathname}`,
          title: `${document.title}`,
          tipo: null,
        });
      }
    }
  }, [trace.traceId, trace.leadId, vehicle, pageTitle]);

  useEffect(() => {
    getDetailVehicle()
  },[slug])

  const registerLog = async () => {
    const result = await logViewed('vehicle', vehicle, window.location.protocol + "//" + window.location.host + window.location.pathname)
  }

  const scrollToCategory = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const getDetailVehicle = async () => {
    const result = await getSpecificVehicle(slug)
    if(result.data.metadata.result >= 1) {
      setVehicle(result.data.data[0])
    } else {
      setVehicle({})
    }
  }

  const buildRequestData = () => {
    const url = window.location.href;

    const lead = new LeadData({
      ...data,
      tipo: 2,
      celular: data.celular ? data.celular.replace(/[^0-9]/g, '') : "",
      additional_Information: `id: ${vehicle.id} - title: ${vehicle.title} - link: ${window.location.href}`,
      cpf: data.cpf ? data.cpf.replace(/[^0-9]/g, '') : "",
      cidade: companyData?.id_city,
      id_company: companyData?.id,
      estado: companyData?.id_uf,
      source: 'simulacao',
      dominio: window.location.host,
      local: window.location.pathname,
      referrer: window.location.href,
      agent: navigator.userAgent,
      // ...traceData,
      trace_id: trace.traceId,
      url,
    });
    
    return lead.getData();
  };

  const getFirstName = (frase) => {
    // Remova os espaços em branco no início e no final da frase
    frase = frase.trim();
    // Divida a frase em palavras usando o espaço como delimitador
    var palavras = frase.split(" ");
    // Pegue o primeiro elemento do array de palavras
    var primeiraPalavra = palavras[0];
    // Retorne a primeira palavra
    return primeiraPalavra;
  }

  const sendSimulation = async (e) => {
    e.preventDefault();

    if(data.nome && data.celular) {
      setLoading(true)
      const leadData = buildRequestData();
      // console.log(leadData)
      const result = await createLead(leadData);
      const leadId = result?.data?.data?.lead_id || null;
      if (leadId) {
        localStorage.setItem('___LeadID', leadId);
        localStorage.setItem('___LeadName', leadData.nome);
        localStorage.setItem('___LeadMobile', leadData.celular);
        localStorage.setItem('___LeadEmail', leadData.email);
        Swal.fire({
          icon: 'success',
          title: `${getFirstName(leadData.nome)}, você deu o primeiro passo para o seu novo carro! 🚀`,
          text: `Em breve entraremos em contato com as melhores opções de parcelamento para o seu novo carro! 🎉🚗💰`,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: result.data.metadata.output.raw,
          text: result.data.metadata.reason,
        });
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Nome e celular não podem ser vazios',
        // text: 'Nome e Celular não podem ser vazios',
      });
    }
    setLoading(false)
  }

  const settings = {
    dots: false,
    arrows: false,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    fade: true,
  };

  return (
    <>
      <Helmet>
        <title>{`${`${vehicle?.seo_title ? vehicle.seo_title : vehicle.title}`}`}</title>
        <meta name="description" content={`${companyData?.seo_title ? companyData.seo_title : companyData.nome} divulga o veículo ${vehicle?.seo_title ? vehicle.seo_title : vehicle.title}`}/>
        <meta name="og:description" content={`${companyData?.seo_title ? companyData.seo_title : companyData.nome} divulga o veículo ${vehicle?.seo_title ? vehicle.seo_title : vehicle.title}`}/>
        <meta property="og:title" content={`${vehicle?.title ? vehicle?.seo_title ? vehicle.seo_title : vehicle.title : ""}`}/>
        <meta property="og:site_name" content={`${vehicle?.title ? vehicle?.seo_title ? vehicle.seo_title : vehicle.title : ""}`}/>
        <meta property="og:url" content={`${window.location.host}${window.location.pathname}`}/>
        <meta property="og:image" content={`${companyData?.logo ? companyData?.logo : `${window.location.host}${LogoRp}`}`}/>
        <meta name="keywords" content={vehicle?.seo_tags ? vehicle?.seo_tags : companyData?.seo_tags}/>
      </Helmet>
      <PageTitle
        pageTitle={vehicle.title}
        // pagesub={'Detalhe Veículo'}
        // pageTitle={t("header-navigation.car_booking")}
        // pagesub={t("header-navigation.car_booking")}
      />
      <section className="gauto-car-booking section_70">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="car-booking-image">
                <Slider {...settings}>
                  {vehicle.media?.map(media => (
                    <img src={media.file} alt={media.filename} key={media.id}/>
                  ))}
                </Slider>
                {/* <img src={img1} alt="car" /> */}
              </div>
            </Col>
            <Col lg={6}>
              <div className="car-booking-right">
                {/* <p className="rental-tag">{t("rental")}</p> */}
                <h3>
                  {vehicle?.price ? currencyFormatter.format(Number(vehicle.price)) : "R$ Consultar"}
                  <a style={{fontSize: 13, marginLeft: 10, textDecoration: 'underline'}} href={'#simular'} onClick={() => scrollToCategory('simular')}>Ver Parcelas</a>
                </h3>
                <div className="price-rating">
                  {/* <div className="price-rent">
                    <h3>
                      {vehicle?.price ? currencyFormatter.format(Number(vehicle.price)) : "R$ Consultar"}
                    </h3>
                  </div> */}
                  {/* <div className="car-rating">
                    <ul>
                      <li>
                        <FaStar />
                      </li>
                      <li>
                        <FaStar />
                      </li>
                      <li>
                        <FaStar />
                      </li>
                      <li>
                        <FaStar />
                      </li>
                      <li>
                        <FaStarHalfAlt />
                      </li>
                    </ul>
                    <p>(123 {t("rating")})</p>
                  </div> */}
                </div>
                {vehicle?.description ?
                  <p>
                    {" "}
                    {vehicle.description}
                  </p>
                : <></>}
                {/* <p>
                  {" "}
                  consectetur adipiscing elit. Donec luctus tincidunt aliquam.
                  Aliquam gravida massa at sem vulputate interdum et vel eros.
                  Maecenas eros enim, tincidunt vel turpis vel,dapibus tempus
                  nulla. Donec vel nulla dui.
                </p> */}
                <div className="car-features clearfix">
                  <ul>
                    {vehicle?.vehicle_type && 
                      <li>
                        <MdCategory /> Tipo: {(vehicleTypeOptions.find(type => type.value == vehicle.vehicle_type)).label}
                      </li>
                    }
                    {vehicle?.plate && 
                      <li>
                        <FaRegKeyboard  /> Placa: {vehicle.plate}
                      </li>
                    }
                    {vehicle?.kilometers &&
                      <li>
                        <FaTachometerAlt /> {vehicle.kilometers} km
                      </li>
                    }
                    {vehicle?.brand &&
                      <li>
                        <FaAppStore  /> Marca: {(brandOptions.find(type => type.value == vehicle.brand)).label}
                      </li>
                    }
                    {vehicle?.engine_type && 
                      <li>
                        <FaCogs /> {(engineOptions.find(type => type.value == vehicle.engine_type)).label}
                      </li>
                    }
                  </ul>
                  <ul>
                    {vehicle?.model || vehicle.model_year ? 
                      <li>
                        <FaCarSide /> Modelo: {vehicle.model && vehicle.model_year ? `${vehicle.model} - ${vehicle.model_year}` : vehicle.model && !vehicle.model_year ? vehicle.model : vehicle.model_year}
                      </li>
                    : <></>}
                    {vehicle?.version && 
                      <li>
                        <FaCarSide /> Versão: {vehicle.version}
                      </li>
                    }
                    {vehicle?.fabrication_year && 
                      <li>
                        <FaCalendar  /> Ano Fabricação: {vehicle.fabrication_year}
                      </li>
                    }
                    {vehicle?.steering &&
                      <li>
                        <FaEmpire /> {(steeringOptions.find(type => type.value == vehicle.steering_type)).label}
                      </li>
                    }
                    {vehicle?.motor_power && 
                      <li>
                        <FaEmpire /> Potência: {(motorOptions.find(type => type.value == vehicle.motor_power)).label}
                      </li>
                    }
                  </ul>
                  <ul>
                    {vehicle?.fuel &&
                      <li>
                        <BsFillFunnelFill  /> Combustível: {(fuelOptions.find(type => type.value == vehicle.fuel)).label}
                      </li>
                    }
                    {vehicle?.gnv_kit && 
                      <li>
                        <FaTools  /> GNV_KIT: {vehicle.gnv_kit ? 'Sim' : 'Não'}
                      </li>
                    }
                    {vehicle?.end_plate &&
                      <li>
                        <FaRegKeyboard /> Final Placa: {(endPlateOptions.find(type => type.value == vehicle.end_plate)).label}
                      </li>
                    }
                    {vehicle?.color &&
                      <li>
                        <FaPaintRoller  /> Cor: {(colorOptions.find(type => type.value == vehicle.color)).label}
                      </li>
                    }
                    {vehicle?.door_count && 
                      <li>
                        <GiCarDoor /> Portas: {(doorCount.find(type => type.value == vehicle.door_count)).label}
                      </li>
                    }
                  </ul>
                </div>
                <div>
                  {Array.isArray(vehicle?.optionals) &&
                    <Row className="mt-4">
                      <Col md="12">
                        <h5>Opcionais</h5>
                        <Row className="" style={{display:'flex'}}>
                          {vehicle.optionals?.map((item) => (
                            <Col md="4" key={item}  className="mt-2">
                              <li>
                                <span>
                                  <FaCheckCircle  style={{marginRight: 3, color: 'green'}}/>
                                  {(optionalsOptions.find(optional => optional.value == item)).label}
                                </span>
                              </li>
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  }
                </div>
                <div>
                  {Array.isArray(vehicle?.documentation_regularization) &&
                    <Row className="mt-5">
                      <Col md="12" style={{}}>
                        <h5>Documentação e Regularização</h5>
                        <Row className="px-2" style={{display:'flex'}}>
                          {vehicle.documentation_regularization?.map((item) => (
                            <Col md="4" key={item}  className="mt-2">
                              <li>
                                <span>
                                  <FaCheckCircle  style={{marginRight: 3, color: 'green'}}/>
                                  {(documentationOptions.find(optional => optional.value == item)).label}
                                </span>
                              </li>
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  }
                </div>
                <div>
                  {Array.isArray(vehicle?.conservation_guarantee) &&
                    <Row className="mt-5">
                      <Col md="12" style={{}}>
                        <h5>Conservação e Garantia</h5>
                        <Row className="px-2" style={{display:'flex'}}>
                          {vehicle.conservation_guarantee?.map((item) => (
                            <Col md="4" key={item} className="mt-2">
                              <li>
                                <span>
                                  <FaCheckCircle  style={{marginRight: 3, color: 'green'}}/>
                                  {(conservationOptions.find(optional => optional.value == item)).label}
                                </span>
                              </li>
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  }
                </div>
              </div>
            </Col>
          </Row>
          {/* {Array.isArray(vehicle?.optionals) &&
            <Row className="mt-4" style={{textAlign: "center"}}>
              <Col md="12">
                <h5>Opcionais</h5>
                <Row className="px-2" style={{display:'flex', justifyContent: 'center'}}>
                  {vehicle.optionals?.map((item) => (
                    <Col md="3" key={item.value}  className="mt-2">
                      <li key={item}>
                        <span>
                          <FaCheckCircle  style={{marginRight: 3, color: 'green'}}/>
                          {(optionalsOptions.find(optional => optional.value == item)).label}
                        </span>
                      </li>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          } */}
          {/* {Array.isArray(vehicle?.documentation_regularization) &&
            <Row className="mt-5" style={{textAlign: "center"}}>
              <Col md="12" style={{}}>
                <h5>Documentação e Regularização</h5>
                <Row className="px-2" style={{display:'flex', justifyContent: 'center'}}>
                  {vehicle.documentation_regularization?.map((item) => (
                    <Col md="3" key={item.value}  className="mt-2">
                      <li key={item}>
                        <span>
                          <FaCheckCircle  style={{marginRight: 3, color: 'green'}}/>
                          {(optionalsOptions.find(optional => optional.value == item)).label}
                        </span>
                      </li>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          } */}
          {/* {Array.isArray(vehicle?.conservation_guarantee) &&
            <Row className="mt-5" style={{textAlign: "center"}}>
              <Col md="12" style={{}}>
                <h5>Conservação e Garantia</h5>
                <Row className="px-2" style={{display:'flex', justifyContent: 'center'}}>
                  {vehicle.conservation_guarantee?.map((item) => (
                    <Col md="3" key={item.value} className="mt-2">
                      <li key={item}>
                        <span>
                          <FaCheckCircle  style={{marginRight: 3, color: 'green'}}/>
                          {(optionalsOptions.find(optional => optional.value == item)).label}
                        </span>
                      </li>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          } */}
        </Container>
      </section>
      <section className="gauto-booking-form section_70" id='simular'>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="booking-form-left">
                <div className="single-booking">
                  <h3>Simular Parcelas</h3>
                  {/* <h3>{t("car_booking.personal_information")}</h3> */}
                  <form onSubmit={(e) => e.preventDefault()}>
                    <Row>
                      <Col md={6}>
                        <p>
                          <label>Nome Completo</label>
                          <input
                            style={{ width: '100%', borderRadius: 10 }}
                            type="text"
                            placeholder='Nome Completo'
                            value={data.nome}
                            onChange={(e) => setData({...data, nome: e.target.value})}
                            // placeholder={t("car_booking.first_name")}
                          />
                        </p>
                      </Col>
                      <Col md={6}>
                        <p>
                          <label>E-mail</label>
                          <input
                            style={{ width: '100%', borderRadius: 10 }}
                            type="email"
                            placeholder='ex: exemplo@email.com'
                            value={data.email}
                            onChange={(e) => setData({...data, email: e.target.value})}
                            // placeholder={t("car_booking.last_name")}
                          />
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <p>
                          <label>CPF</label>
                          <InputMask
                            mask="999.999.999-99"
                            placeholder="000.000.000-00"
                            value={data.cpf}
                            onChange={(e) => setData({...data, cpf: e.target.value})}
                          >
                            {(inputProps) => (
                              <input
                                {...inputProps}
                                type="text"
                                style={{ width: '100%', borderRadius: 10 }}
  
                              />
                            )}
                          </InputMask>
                          {/* <input
                            type="text"
                            placeholder='999.999.999-99'
                            // placeholder={t("car_booking.email")}
                          /> */}
                        </p>
                      </Col>
                      <Col md={6}>
                        <p>
                          <label>Celular</label>
                          <input
                            style={{ width: '100%', borderRadius: 10 }}
                            type="tel"
                            placeholder='ex: (99) 99999-9999'
                            value={phoneMask(data.celular)}
                            onChange={(e) => setData({...data, celular: phoneMask(e.target.value)})}
                            // placeholder={t("car_booking.phn")}
                          />
                        </p>
                      </Col>
                    </Row>
                  </form>
                </div>
                {/* <div className="single-booking">
                  <h3>{t("car_booking.booking_details")}</h3>
                  <form>
                    <Row>
                      <Col md={6}>
                        <p>
                          <input type="text" placeholder={t("from_address")} />
                        </p>
                      </Col>
                      <Col md={6}>
                        <p>
                          <input type="text" placeholder={t("to_address")} />
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <p>
                          <select>
                            <option data-display="Select">1 person</option>
                            <option>2 person</option>
                            <option>3 person</option>
                            <option>4 person</option>
                            <option>5-10 person</option>
                          </select>
                        </p>
                      </Col>
                      <Col md={6}>
                        <p>
                          <select>
                            <option data-display="Select">1 luggage</option>
                            <option>2 luggage</option>
                            <option>3 luggage</option>
                            <option>4(+) luggage</option>
                          </select>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <p>
                          <DatePickerComponent
                            id="datepicker"
                            placeholder={t("journey_date")}
                          ></DatePickerComponent>
                        </p>
                      </Col>
                      <Col md={6}>
                        <p>
                          <TimePickerComponent
                            id="timepicker"
                            placeholder={t("journey_time")}
                          ></TimePickerComponent>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <p>
                          <textarea
                            placeholder="Write Here..."
                            defaultValue={""}
                          />
                        </p>
                      </Col>
                    </Row>
                  </form>
                </div> */}
                <div className="action-btn">
                  <Link to="" onClick={(e) => loading ? {} : sendSimulation(e)} className="gauto-btn">
                    {loading ? <div className="spinner-grow" role="status" style={{marginRight: 5, height: 20, width: 20}}></div> : <></>}
                    Simular
                    {/* {t("researve_now")} */}
                  </Link>
                </div>
              </div>
            </Col>
            {/* <Col lg={4}>
              <div className="booking-right">
                <h3>{t("car_booking.payment_method")}</h3>
                <div className="gauto-payment clearfix">
                  <div className="payment">
                    <input type="radio" id="ss-option" name="selector" />
                    <label htmlFor="ss-option">
                      {t("car_booking.bank_transfer")}
                    </label>
                    <div className="check">
                      <div className="inside" />
                    </div>
                    <p>{t("car_booking.payment_text")}</p>
                  </div>
                  <div className="payment">
                    <input type="radio" id="f-option" name="selector" />
                    <label htmlFor="f-option">
                      {t("car_booking.check_payment")}
                    </label>
                    <div className="check">
                      <div className="inside" />
                    </div>
                  </div>
                  <div className="payment">
                    <input type="radio" id="s-option" name="selector" />
                    <label htmlFor="s-option">
                      {t("car_booking.credit_card")}
                    </label>
                    <div className="check">
                      <div className="inside" />
                    </div>
                    <img src={img2} alt="credit card" />
                  </div>
                  <div className="payment">
                    <input type="radio" id="t-option" name="selector" />
                    <label htmlFor="t-option">Paypal</label>
                    <div className="check">
                      <div className="inside" />
                    </div>
                    <img src={img3} alt="credit card" />
                  </div>
                </div> 
                <div className="action-btn">
                  <Link to="/" onClick={onClick} className="gauto-btn">
                    {t("researve_now")}
                  </Link>
                </div>
              </div>
            </Col> */}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default CarBooking;
