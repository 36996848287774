import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaUser,
  FaCalendarAlt,
  FaCommentAlt,
  FaQuoteRight,
  FaReply,
} from "react-icons/fa";
import BlogSidebar from "../BlogSidebar";
import img1 from "../../img/blog-2.jpg";
import img2 from "../../img/blog-3.jpg";
import comment1 from "../../img/4.jpg";
import comment2 from "../../img/5.jpg";

import "./style.css";
import PageTitle from "../PageTitle";
import { getNewsSlug } from "../../services/news/requests";

import NoImage from '../../img/sem-foto.jpg';
import { dateFormatterLong } from './../../utils/convertDates';
import useTraceId from "../../hooks/useTraceId";
import { LeadContext } from "../../contexts/LeadContext";
import { Helmet } from "react-helmet";
import { CompanyContext } from "../../contexts/CompanyContext";
import { WhatsAppContext } from "../../contexts/WhatsappContext";

import LogoRp from '../../img/logo-rp.png'
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";

const BlogDetails = () => {
  const { t } = useTranslation();
  const { slug } = useParams();

  const [news, setNews] = useState(null);
  const {companyData} = useContext(CompanyContext);
  const { changeData } = useContext(WhatsAppContext);
  const trace = useTraceId();
  const { traceData } = useContext(LeadContext);

  const [pageTitle, setPageTitle] = useState("");
  const [lastPageTitle, setLastPageTitle] = useState(document.title);

  useEffect(() => {
    setPageTitle(document.title);
  }, [document.title]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    if(slug) {
      // console.log(slug)
      getNew()
    }
  },[slug])

  useEffect(() => {
    if(news?.id && companyData?.id) {
      changeData({
        phone: companyData?.whatsapp_number,
        message: `Gostaria de saber mais sobre o artigo ${news.title}.`
      })
    }
  },[news, companyData])

  useEffect(() => {
    if(news?.id && pageTitle !== lastPageTitle) {
      if (trace.traceId) {
        trace.setTraceParams({
          ...traceData,
          trid: trace.traceId,
          crm_lead_id: trace.leadId,
          dominio: `${window.location.origin}`,
          local: `${window.location.pathname}`,
          title: `${document.title}`,
          tipo: null,
        });
      }
    }
  }, [trace.traceId, trace.leadId, news, pageTitle]);

  const getNew = async () => {
    // console.log(slug)
    const result = await getNewsSlug(slug)
    // console.log(result)
    if(result.data.metadata.result >= 1) {
      setNews(result.data.data[0])
    } 
  }

  return (news ?
    <>
      <Helmet>
        <title>{`${news?.seo_title ? news.seo_title : news?.title}`}</title>
        <meta name="description" content={`${companyData?.seo_title ? companyData.seo_title : companyData.nome} postou o artigo ${news?.seo_title ? news.seo_title : news?.title}`}/>
        <meta name="og:description" content={`${companyData?.seo_title ? companyData.seo_title : companyData.nome} postou o artigo ${news?.seo_title ? news.seo_title : news?.title}`}/>
        <meta property="og:title" content={`${news?.seo_title ? news.seo_title : news?.title}`}/>
        <meta property="og:site_name" content={`${news?.seo_title ? news.seo_title : news?.title}`}/>
        <meta property="og:url" content={`${window.location.host}${window.location.pathname}`}/>
        <meta property="og:image" content={`${companyData?.logo ? companyData?.logo : `${window.location.host}${LogoRp}`}`}/>
        <meta name="keywords" content={news?.seo_tags ? news?.seo_tags : companyData?.seo_tags}/>
      </Helmet>
      <PageTitle
        pageTitle={news?.title}
        // pagesub={t("header-navigation.blog_single")}
        // pageTitle={t("header-navigation.blog_single")}
        // pagesub={t("header-navigation.blog_single")}
      />
      <section className="gauto-blog-page-area section_70">
        <Container>
          <Row>
            <Col lg={8} md={7}>
              <div className="blog-page-left single-blog-page">
                <div className="single-blog">
                  <div className="blog-image">
                    <img src={news ? news.media.length > 0 ? news.media[0].file : NoImage : NoImage} alt="blog 1" style={{height: 426, width: 640}}/>
                  </div>
                  <div className="blog-text">
                    <h3>{news?.title}</h3>
                    <ul>
                      {/* <li>
                        <FaUser /> <Link to="/">Bizkit</Link>
                      </li> */}
                      <li>
                        <FaCalendarAlt />
                        {news?.created_at ? dateFormatterLong.format(new Date(news.created_at)) : ""}
                      </li>
                      {/* <li>
                        <FaCommentAlt />
                        <Link to="/">(05) {t("blog_page.comments")}</Link>
                      </li> */}
                    </ul>
                    <div dangerouslySetInnerHTML={{__html: news?.description}}></div>
                    {/* <p>
                      Magna etiam tempor orci eu. Risus in hendrerit gravida
                      rutrum quisque non tellus orci. Fames ac turpis egestas
                      hendrerit gravida.
                    </p>
                    <p>
                      Dolor consectetur adipiscing elit sed do eiusmod tempor
                      incididunt know you labore et dolore magna aliqua
                      consectetur adipiscingLoren ipsum dolor conse ctetur sed
                      adipi scing elit to sed do eiusmod tempor ipsum dolor
                      consectetur adipiscing elit sed do eiu smod temp inci did
                      know you labore et dolore.
                    </p>
                    <p>
                      craft beer labore wes anderson cred nesciunt sapiente ea
                      proident. Ad vegan excepteur butcher vice lomo. Leggings
                      occaecat craft beer farm-to-table, raw denim aesthetic synth
                      nesciunt you probably haven't heard of them accusamus labore
                      sustainable VHS.aliqua consectetur adipiscingLoren ipsum
                      dolor conse ctetur sed adipi scing elit to sed do eiusmod
                      tempor ipsum dolor consectetur{" "}
                    </p>
                    <div className="blog-content-inner">
                      <Row>
                        <Col md={4}>
                          <div className="blog-content-img">
                            <img src={img2} alt="blog" />
                          </div>
                        </Col>
                        <Col md={8}>
                          <div className="blog-content-text">
                            <p>
                              Magna etiam tempor orci eu. Risus in hendrerit
                              gravida rutrum quisque non tellus orci. Fames ac
                              turpis egestas maecenas pharetra convallis posuere
                              morbi leo. Nibh sit amet commodo nulla. At erat
                              pellentesque{" "}
                            </p>
                            <p>
                              In dapibus urna sit amet accumsan tristique. Donec
                              odio ligula, luctus venenatis varius id, tincidunt
                              ac ipsum. Cras commodo,
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <p>
                      Dolor consectetur adipiscing elit sed do eiusmod tempor
                      incididunt know you labore et dolore magna aliqua
                      consectetur adipiscingLoren ipsum dolor conse ctetur sed
                      adipi scing elit to sed do eiusmod tempor ipsum dolor
                      consectetur adipiscing elit sed do eiu smod temp inci did
                      know you labore et dolore magna aliqua consec consectetur.
                    </p>
                    <blockquote>
                      <div className="quote-inner">
                        <FaQuoteRight className="quote-icon" />
                        <div className="quote-text">
                          Beatae vitae dicta sunt explicabo. Nemo enim ipsam
                          voluptatem quia voluptas sit aspernatur aut odit aut
                          fugit, sed quia consequuntur magni dolores.
                        </div>
                      </div>
                    </blockquote>
                    <p>
                      raw denim aesthetic synth nesciunt you probably haven't
                      heard of them accusamus labore sustainable VHS.aliqua
                      consectetur adipiscingLoren ipsum dolor conse ctetur sed
                      adipi scing elit to sed do eiusmod tempor ipsum dolor
                      consectetur{" "}
                    </p> */}
                  </div>
                  {/* <div className="gauto-comment-list">
                    <div className="comment-group-title">
                      <h2>3 {t("comments")}</h2>
                    </div>
                    <div className="single-comment-item">
                      <div className="single-comment-box">
                        <div className="main-comment">
                          <div className="author-image">
                            <img src={comment1} alt="author" />
                          </div>
                          <div className="comment-text">
                            <div className="comment-info">
                              <h4>david kamal</h4>
                              <p>12 FEB 2019</p>
                              <Link to="/">
                                <FaReply />
                                Reply
                              </Link>
                            </div>
                            <div className="comment-text-inner">
                              <p>
                                Ne erat velit invidunt his. Eum in dicta veniam
                                interesset, harum lupta definitionem. Vocibus
                                suscipit prodesset vim ei, equidem perpetua eu
                                per.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single-comment-box reply-comment">
                        <div className="main-comment">
                          <div className="author-image">
                            <img src={comment2} alt="author" />
                          </div>
                          <div className="comment-text">
                            <div className="comment-info">
                              <h4>Danial Martin</h4>
                              <p>12 FEB 2019</p>
                              <Link to="/">
                                <FaReply />
                                Reply
                              </Link>
                            </div>
                            <div className="comment-text-inner">
                              <p>
                                Ne erat velit invidunt his. Eum in dicta veniam
                                interesset, harum lupta definitionem. Vocibus
                                suscipit prodesset vim ei, equidem perpetua eu
                                per.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single-comment-box">
                        <div className="main-comment">
                          <div className="author-image">
                            <img src={comment1} alt="author" />
                          </div>
                          <div className="comment-text">
                            <div className="comment-info">
                              <h4>sumaiya mim</h4>
                              <p>12 FEB 2019</p>
                              <Link to="/">
                                <FaReply />
                                Reply
                              </Link>
                            </div>
                            <div className="comment-text-inner">
                              <p>
                                Ne erat velit invidunt his. Eum in dicta veniam
                                interesset, harum lupta definitionem. Vocibus
                                suscipit prodesset vim ei, equidem perpetua eu
                                per.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="gauto-leave-comment">
                    <h2>{t("leave_comment")}</h2>
                    <p>{t("must_signin")}</p>
                    <form>
                      <input
                        className="ns-com-name"
                        name="name"
                        placeholder={t("c_name")}
                        type="text"
                      />
                      <input
                        className="ns-com-name"
                        name="email"
                        placeholder={t("c_email")}
                        type="email"
                      />
                      <textarea
                        className="comment"
                        placeholder={t("comments")}
                        name="comment"
                        defaultValue={""}
                      />
                      <button type="submit" className="gauto-theme-btn">
                        {t("post_comment")}
                      </button>
                    </form>
                  </div> */}
                </div>
              </div>
            </Col>
            <Col lg={4} md={5}>
              <BlogSidebar />
            </Col>
          </Row>
        </Container>
      </section>
    </>
    : <></>
  );
};

export default BlogDetails;
