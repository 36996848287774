export const brandOptions = [
  { value: 1, label: "ADAMO" },
  { value: 2, label: "AGNIS" },
  { value: 3, label: "ACURA" },
  { value: 4, label: "AGRALE" },
  { value: 5, label: "ALFAROMEO" },
  { value: 6, label: "AMAZONAS" },
  { value: 7, label: "AMERICAR" },
  { value: 8, label: "ANGRA" },
  { value: 9, label: "ASIA" },
  { value: 10, label: "ASTONMARTIN" },
  { value: 11, label: "AUDI" },
  { value: 12, label: "AUSTIN-HEALEY" },
  { value: 13, label: "AVALLONE" },
  { value: 14, label: "BAJA" },
  { value: 15, label: "BEACH" },
  { value: 16, label: "BENTLEY" },
  { value: 17, label: "BIANCO" },
  { value: 18, label: "BMW" },
  { value: 19, label: "BORGWARD" },
  { value: 20, label: "BRASFIBRA" },
  { value: 21, label: "BRILLIANCE" },
  { value: 22, label: "BRM" },
  { value: 23, label: "BUGATTI" },
  { value: 24, label: "BUGRE" },
  { value: 25, label: "BUGWAY" },
  { value: 26, label: "BUICK" },
  { value: 27, label: "CADILLAC" },
  { value: 28, label: "CAUYPE" },
  { value: 29, label: "CBP" },
  { value: 30, label: "CBT" },
  { value: 31, label: "CHAMONIX" },
  { value: 32, label: "CHANA" },
  { value: 33, label: "CHANGAN" },
  { value: 34, label: "CHEDA" },
  { value: 35, label: "CHERY" },
  { value: 36, label: "CHEVROLET" },
  { value: 37, label: "CHRYSLER" },
  { value: 38, label: "CITROÃâ¹N" },
  { value: 39, label: "CORD" },
  { value: 40, label: "COYOTE" },
  { value: 41, label: "CROSSLANDER" },
  { value: 42, label: "DACON" },
  { value: 43, label: "DAEWOO" },
  { value: 44, label: "DAIHATSU" },
  { value: 45, label: "DATSUN" },
  { value: 46, label: "DESOTO" },
  { value: 47, label: "DETOMASO" },
  { value: 48, label: "DELOREAN" },
  { value: 49, label: "DKW-VEMAG" },
  { value: 50, label: "DODGE" },
  { value: 51, label: "DUNNAS" },
  { value: 52, label: "EAGLE" },
  { value: 53, label: "EFFA" },
  { value: 54, label: "EGO" },
  { value: 55, label: "EMIS" },
  { value: 56, label: "ENGESA" },
  { value: 57, label: "ENSEADA" },
  { value: 58, label: "ENVEMO" },
  { value: 59, label: "FARGO" },
  { value: 60, label: "FARUS" },
  { value: 61, label: "FERCARBUGGY" },
  { value: 62, label: "FERRARI" },
  { value: 63, label: "FIAT" },
  { value: 64, label: "FIBRARIO" },
  { value: 65, label: "FIBRAVAN" },
  { value: 66, label: "FISKER" },
  { value: 67, label: "FNM" },
  { value: 68, label: "FORD" },
  { value: 69, label: "FOTON" },
  { value: 70, label: "FYBER" },
  { value: 71, label: "GEELY" },
  { value: 72, label: "GEO" },
  { value: 73, label: "GLASPAC" },
  { value: 74, label: "GMC" },
  { value: 75, label: "GRANCAR" },
  { value: 76, label: "GREATWALL" },
  { value: 77, label: "GURGEL" },
  { value: 78, label: "HAFEI" },
  { value: 79, label: "HB" },
  { value: 80, label: "HOFSTETTER" },
  { value: 81, label: "HONDA" },
  { value: 82, label: "HUDSON" },
  { value: 83, label: "HUMMER" },
  { value: 84, label: "HYUNDAI" },
  { value: 85, label: "ICOMDA" },
  { value: 86, label: "INCOFER" },
  { value: 87, label: "INFINITI" },
  { value: 88, label: "INTERNATIONAL" },
  { value: 89, label: "ISUZU" },
  { value: 90, label: "IVECO" },
  { value: 91, label: "JAC" },
  { value: 92, label: "JAGUAR" },
  { value: 93, label: "JEEP" },
  { value: 94, label: "JENSEN" },
  { value: 95, label: "JINBEI" },
  { value: 96, label: "JONWAY" },
  { value: 97, label: "JPX" },
  { value: 98, label: "KAISER" },
  { value: 99, label: "KIA" },
  { value: 100, label: "KOENIGSEGG" },
  { value: 101, label: "LAUTOMOBILE" },
  { value: 102, label: "LADA" },
  { value: 103, label: "LAMBORGHINI" },
  { value: 104, label: "LANCIA" },
  { value: 105, label: "LANDROVER" },
  { value: 106, label: "LANDWIND" },
  { value: 107, label: "LEXUS" },
  { value: 108, label: "LHM" },
  { value: 109, label: "LIFAN" },
  { value: 110, label: "LINCOLN" },
  { value: 111, label: "LOBINI" },
  { value: 112, label: "LOTUS" },
  { value: 113, label: "LÃÂ´AUTOCRAFT" },
  { value: 114, label: "MAHINDRA" },
  { value: 115, label: "MARCOPOLO" },
  { value: 116, label: "MARINAÃÂ´S" },
  { value: 117, label: "MASERATI" },
  { value: 118, label: "MATRA" },
  { value: 119, label: "MAYBACH" },
  { value: 120, label: "MAZDA" },
  { value: 121, label: "MCLAREN" },
  { value: 122, label: "MENON" },
  { value: 123, label: "MERCEDES-BENZ" },
  { value: 124, label: "MERCURY" },
  { value: 125, label: "MG" },
  { value: 126, label: "MINI" },
  { value: 127, label: "MITSUBISHI" },
  { value: 128, label: "MIURA" },
  { value: 129, label: "MOBBY" },
  { value: 130, label: "MORGAN" },
  { value: 131, label: "MORRIS" },
  { value: 132, label: "MPLAFER" },
  { value: 133, label: "MPLM" },
  { value: 134, label: "NASH" },
  { value: 135, label: "NEWTRACK" },
  { value: 136, label: "NISSAN" },
  { value: 137, label: "NURBURGRING" },
  { value: 138, label: "OLDSMOBILE" },
  { value: 139, label: "OPEL" },
  { value: 140, label: "PACKARD" },
  { value: 141, label: "PAG" },
  { value: 142, label: "PAGANI" },
  { value: 143, label: "PEUGEOT" },
  { value: 144, label: "PLYMOUTH" },
  { value: 145, label: "PONTIAC" },
  { value: 146, label: "PORSCHE" },
  { value: 147, label: "PUMA" },
  { value: 148, label: "RAM" },
  { value: 149, label: "RDK" },
  { value: 150, label: "RELY" },
  { value: 151, label: "RENAULT" },
  { value: 152, label: "RENO" },
  { value: 153, label: "REVA-I" },
  { value: 154, label: "RILEY" },
  { value: 155, label: "ROLLS-ROYCE" },
  { value: 156, label: "ROMI" },
  { value: 157, label: "ROVER" },
  { value: 158, label: "SAAB" },
  { value: 159, label: "SANTAMATILDE" },
  { value: 160, label: "SATURN" },
  { value: 161, label: "SEAT" },
  { value: 162, label: "SELVAGEM" },
  { value: 163, label: "SHELBY" },
  { value: 164, label: "SHINERAY" },
  { value: 165, label: "SHORT" },
  { value: 166, label: "SHUANGHUAN" },
  { value: 167, label: "SIMCA" },
  { value: 168, label: "SMART" },
  { value: 169, label: "SPYKER" },
  { value: 170, label: "SSANGYONG" },
  { value: 171, label: "STANDARD" },
  { value: 172, label: "STUDEBAKER" },
  { value: 173, label: "SUBARU" },
  { value: 174, label: "SUZUKI" },
  { value: 175, label: "TAC" },
  { value: 176, label: "TANDER" },
  { value: 177, label: "TANGER" },
  { value: 178, label: "TESLA" },
  { value: 179, label: "TOYOTA" },
  { value: 180, label: "TRIUMPH" },
  { value: 181, label: "TROLLER" },
  { value: 182, label: "UNIMOG" },
  { value: 183, label: "VENDETTA" },
  { value: 184, label: "VOLKSWAGEN" },
  { value: 185, label: "VOLVO" },
  { value: 186, label: "WAKE" },
  { value: 187, label: "WALK" },
  { value: 188, label: "WAYBRASIL" },
  { value: 189, label: "WHIPPET" },
  { value: 190, label: "WIESMANN" },
  { value: 191, label: "WILLYS" },
  { value: 192, label: "WILLYSOVERLAND" },
  { value: 193, label: "ZIMMER" },
];

export const fuelOptions = [
  { value: 1, label: "Álcool" },
  { value: 2, label: "Álcool e gás natural" },
  { value: 3, label: "Diesel" },
  { value: 4, label: "Gás natural" },
  { value: 5, label: "Gasolina" },
  { value: 6, label: "Flex" },
  { value: 7, label: "Gasolina e elétrico" },
  { value: 8, label: "Gasolina e gás natural" },
  { value: 9, label: "Gasolina, álcool e gás natural" },
  { value: 10, label: "Gasolina, álcool, gás natural e benzina" },
  { value: 11, label: "Elétrico" },
];

export const engineOptions = [
  { value: 1, label: "Automático" },
  { value: 2, label: "Automático Sequencial" },
  { value: 3, label: "CVT" },
  { value: 4, label: "Manual" },
  { value: 5, label: "Semi-automático" },
];

export const doorCount = [
  { value: 1, label: "2" },
  { value: 2, label: "3" },
  { value: 3, label: "4" },
];

export const colorOptions = [
  { value: 1, label: "Amarelo" },
  { value: 2, label: "Azul" },
  { value: 3, label: "Bege" },
  { value: 4, label: "Branco" },
  { value: 5, label: "Bronze" },
  { value: 6, label: "Cinza" },
  { value: 7, label: "Dourado" },
  { value: 8, label: "Indefinida" },
  { value: 9, label: "Laranja" },
  { value: 10, label: "Marrom" },
  { value: 11, label: "Prata" },
  { value: 12, label: "Preto" },
  { value: 13, label: "Rosa" },
  { value: 14, label: "Roxo" },
  { value: 15, label: "Verde" },
  { value: 16, label: "Vermelho" },
  { value: 17, label: "Vinho" },
];

export const optionalsOptions = [
  {
    label: "Blindado",
    value: "1",
  },
  {
    label: "Air bag do motorista",
    value: "2",
  },
  {
    label: "Air bag duplo",
    value: "3",
  },
  {
    label: "Alarme",
    value: "4",
  },
  {
    label: "Ar condicionado",
    value: "5",
  },
  {
    label: "Ar condicionado digital",
    value: "6",
  },
  {
    label: "Ar quente",
    value: "7",
  },
  {
    label: "Assistente de Partida em Rampa",
    value: "8",
  },
  {
    label: "Banco bi-partido",
    value: "9",
  },
  {
    label: "Banco do motorista com ajuste de altura",
    value: "10",
  },
  {
    label: "Bancos de couro",
    value: "11",
  },
  {
    label: "Bancos dianteiros com aquecimento",
    value: "12",
  },
  {
    label: "Capota marítima",
    value: "13",
  },
  {
    label: "Comando de áudio e telefone no volante",
    value: "14",
  },
  {
    label: "Computador de bordo",
    value: "15",
  },
  {
    label: "Controle automático de velocidade",
    value: "16",
  },
  {
    label: "Controle de estabilidade",
    value: "17",
  },
  {
    label: "Controle de tração",
    value: "18",
  },
  {
    label: "Desembaçador traseiro",
    value: "19",
  },
  {
    label: "Direção Elétrica",
    value: "20",
  },
  {
    label: "Direção hidráulica",
    value: "21",
  },
  {
    label: "Disqueteira",
    value: "22",
  },
  {
    label: "Distribuição eletrônica de frenagem",
    value: "23",
  },
  {
    label: "Encosto de cabeça traseiro",
    value: "24",
  },
  {
    label: "Farol de neblina",
    value: "25",
  },
  {
    label: "Farol xenônio",
    value: "26",
  },
  {
    label: "Freio ABS",
    value: "27",
  },
  {
    label: "GPS",
    value: "28",
  },
  {
    label: "Kit Gás",
    value: "29",
  },
  {
    label: "Kit Multimídia",
    value: "30",
  },
  {
    label: "Limpador traseiro",
    value: "31",
  },
  {
    label: "MP3 Player",
    value: "32",
  },
  {
    label: "Pára-choques na cor do veículo",
    value: "33",
  },
  {
    label: "Porta-copos",
    value: "34",
  },
  {
    label: "Protetor de caçamba",
    value: "35",
  },
  {
    label: "Rádio",
    value: "36",
  },
  {
    label: "Rádio e CD Player",
    value: "37",
  },
  {
    label: "Rádio e toca fitas",
    value: "38",
  },
  {
    label: "Retrovisor fotocrômico",
    value: "39",
  },
  {
    label: "Retrovisores elétricos",
    value: "40",
  },
  {
    label: "Rodas de liga leve",
    value: "41",
  },
  {
    label: "Sensor de chuva",
    value: "42",
  },
  {
    label: "Sensor de estacionamento",
    value: "43",
  },
  {
    label: "Teto solar",
    value: "44",
  },
  {
    label: "Tração 4x4",
    value: "45",
  },
  {
    label: "Travas elétricas",
    value: "46",
  },
  {
    label: "Vidros elétricos",
    value: "47",
  },
  {
    label: "Volante com Regulagem de Altura",
    value: "48",
  },
];

export const documentationOptions = [
  {
    label: "IPVA Pago",
    value: "1",
  },
  {
    label: "Com Multas",
    value: "2",
  },
  {
    label: "Leilão",
    value: "3",
  },
];

export const conservationOptions = [
  {
    label: "Único Dono",
    value: "1",
  },
  {
    label: "Com Manual",
    value: "2",
  },
  {
    label: "Chave Reserva",
    value: "3",
  },
  {
    label: "Revisões em concessionárias",
    value: "4",
  },
  {
    label: "Com Garantia",
    value: "5",
  },
];

export const steeringOptions = [
  { value: 1, label: "Hidráulica" },
  { value: 2, label: "Elétrica" },
  { value: 3, label: "Mecânica" },
  { value: 4, label: "Assistida" },
];

export const motorOptions = [
  { label: "1.0", value: 1 },
  { label: "1.2", value: 2 },
  { label: "1.3", value: 3 },
  { label: "1.4", value: 4 },
  { label: "1.5", value: 5 },
  { label: "1.6", value: 6 },
  { label: "1.7", value: 7 },
  { label: "1.8", value: 8 },
  { label: "1.9", value: 9 },
  { label: "2.0 - 2.9", value: 10 },
  { label: "3.0 - 3.9", value: 11 },
  { label: "4.0 ou mais", value: 12 },
];

export const vehicleTypeOptions = [
  { value: 1, label: "Carro" },
  { value: 2, label: "Moto" },
];

export const endPlateOptions = [
  { value: 0, label: "0" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
];
