import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Homepage from "../HomePage";
import AboutPage from "../AboutPage";
import ServicePage from "../ServicePage";
import ServiceSingle from "../ServiceDetails";
import CarListingPage from "../CarListingPage";
import CarBookingPage from "../CarBookingPage";
import GalleryPage from "../GalleryPage";
import ProductPage from "../ProductPage";
import ProductSinglePage from "../ProductSingle";
import CartPage from "../CartPage";
import Checkout from "../Checkout";
import BlogPage from "../BlogPage";
import BlogSinglePage from "../BlogSinglePage";
import ErrorPage from "../ErrorPage";
import LoginPage from "../LoginPage";
import RegisterPage from "../RegisterPage";
import ContactPage from "../ContactPage";
import { CompanyProvider } from "../../contexts/CompanyContext";
import DynamicPage from "../DynamicPage";
import { PageProvider } from "../../contexts/PagesContext";
import { WhatsAppProvider } from "../../contexts/WhatsappContext";
import { LeadProvider } from "../../contexts/LeadContext";
import Maintenance from "../../components/Maintenance";
import JobPage from "../JobPage";
import JobDetailPage from "../JobDetailPage";
import FormRegisterPage from "../FormRegisterPage";
import FormAvaliationPage from "../FormAvaliationPage";

const AllRoute = () => {

  // const [pages, setPages] = useState([]);

  // useEffect(() => {
  //   getPagesList()
  // },[])

  // const getPagesList = async () => {
  //   const result = await getPages({}, 2782)
  //   if(result.data.metadata.result >= 1) {
  //     setPages(result.data.data.data)
  //   }
  //   // console.log(result.data.data.data)
  // }

  return (
    <div>
      <Router>
        <CompanyProvider>
          <LeadProvider>
            <WhatsAppProvider>
              <PageProvider>
                <Routes>
                    <Route exact path="/" element={<Homepage />} />
                    <Route path="/home" element={<Homepage />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/servicos" element={<ServicePage />} />
                    <Route path="/service-single" element={<ServiceSingle />} />
                    <Route path="/nosso-estoque" element={<CarListingPage />} />
                    <Route path="/veiculo/:slug" element={<CarBookingPage />} />
                    <Route path="/vagas" element={<JobPage />} />
                    <Route path="/vaga/:slug" element={<JobDetailPage />} />
                    <Route path="/cadastro" element={<FormRegisterPage />} />
                    <Route path="/avaliacao" element={<FormAvaliationPage />} />
                    <Route path="/gallery" element={<GalleryPage />} />
                    <Route path="/product" element={<ProductPage />} />
                    <Route path="/product-single" element={<ProductSinglePage />} />
                    <Route path="/cart" element={<CartPage />} />
                    <Route path="/checkout" element={<Checkout />} />
                    <Route path="/artigos" element={<BlogPage />} />
                    <Route path="/artigo/:slug" element={<BlogSinglePage />} />
                    <Route path="/error" element={<ErrorPage />} />
                    <Route path="/manutencao" element={<Maintenance />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/register" element={<RegisterPage />} />
                    <Route path="/contato" element={<ContactPage />} />
                    <Route element={<ErrorPage />} />
                    <Route path={`/:slug`} element={<DynamicPage/>}/>
                </Routes>
              </PageProvider>
            </WhatsAppProvider>
          </LeadProvider>
        </CompanyProvider>
      </Router>
    </div>
  );
};

export default AllRoute;
