import React, { useContext, useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import { useSSR, useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaPaperPlane,
} from "react-icons/fa";

import "./style.css";
import { CompanyContext } from '../../contexts/CompanyContext';
import { phoneMask } from '../../utils/maskForPhone';
// import { formatarCEP } from "../../utils/formatCep";
import { LeadData } from "../../contexts/leadData";
import useTraceId from "../../hooks/useTraceId";
import { createLead } from '../../services/lead/requests';
import Swal from 'sweetalert2';
import Select from "react-select";
import { brandOptions, colorOptions, fuelOptions } from "../../utils/dataForVehicles";

const FormAvaliation = () => {

  // const { t } = useTranslation();
  const {companyData} = useContext(CompanyContext);
  const [loading, setLoading] = useState(false);
  const trace = useTraceId();
  const [data, setData] = useState({nome: "", email: "", celular: "", veiculo: "", marca: "", modelo: "", ano_modelo: "", ano_fabricacao: "", motivo: "", cor: "", combustivel: ""})

  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [optionsVehicleType, setOptionsVehicleType] = useState([
    { value: "caminhao", label: "Caminhão" },
    { value: "carreta", label: "Carreta" },
    { value: "carro/caminhonete", label: "Carro/Caminhote" },
    { value: "implemento_rodoviario", label: "Implemento Rod." },
    { value: "moto", label: "Moto" },
    { value: "motor_home", label: "Moto Home" },
    { value: "nautica", label: "Náutica" },
    { value: "onibus", label: "Ônibus" },
    { value: "quadriciclo/triciclo", label: "Quadriciclo/Triciclo" },
    { value: "trator/maquinas", label: "Trator/Máquinas" },
  ]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [optionsBrands, setOptionsBrands] = useState(brandOptions);
  const [selectedFuel, setSelectedFuel] = useState(null);
  const [optionsFuel, setOptionsFuel] = useState(fuelOptions);
  const [selectedColor, setSelectedColor] = useState(null);
  const [optionsColors, setOptionsColors] = useState(colorOptions);
  const [selectedReason, setSelectedReason] = useState(null);
  const [optionsReasons, setOptionsReasons] = useState([
    {valur: 1, label: "Quero vender meu carro"},
    {valur: 2, label: "Quero trocar meu carro"},
    {valur: 3, label: "Quero consignar meu carro"},
  ]);

  const [errors, setErrors] = useState({
    nome: false,
    celular: false,
    motivo: false,
    tipo: false,
    marca: false,
    modelo: false,
    ano_modelo: false,
    ano_fabricacao: false,
    cor: false,
    combustivel: false
  })

  useEffect(() => {
    if(localStorage.getItem('___LeadID')){
      setData({nome: localStorage.getItem('___LeadName'), email: localStorage.getItem('___LeadEmail'), celular: localStorage.getItem('___LeadMobile')})
    }
  },[])

  const buildRequestData = () => {
    const url = window.location.href;

    const lead = new LeadData({
      email: data.email,
      nome: data.nome,
      celular: data.celular ? data.celular.replace(/[^0-9]/g, '') : "",
      cidade: companyData?.id_city,
      id_company: companyData?.id,
      estado: companyData?.id_uf,
      datanasc: data.datanasc,
      tipo: 2,
      source: 'avaliacao',
      additional_Information: `motivo da avaliação: ${data.motivo} ; tipo veículo: ${data.veiculo} ; marca: ${data.marca} ; modelo: ${data.modelo} ; ano modelo: ${data.ano_modelo} ; ano fabricação: ${data.ano_fabricacao} ; cor: ${data.cor} ; combustível: ${data.combustivel}`,
      dominio: window.location.host,
      local: window.location.pathname,
      referrer: window.location.href,
      agent: navigator.userAgent,
      // ...traceData,
      trace_id: trace.traceId,
      url,
    });
    
    return lead.getData();
  };

  const validateFields = () => {
    const newErrors = {
      nome: false,
      celular: false,
      motivo: false,
      tipo: false,
      marca: false,
      modelo: false,
      ano_modelo: false,
      ano_fabricacao: false,
      cor: false,
      combustivel: false
    };

    if (!data.nome) {
      newErrors.nome = true;
    }
    if (!data.celular) {
      newErrors.celular = true;
    }
    if (!data.motivo) {
      newErrors.motivo = true;
    }
    if (!data.veiculo) {
      newErrors.tipo = true;
    }
    if (!data.marca) {
      newErrors.marca = true;
    }
    if (!data.modelo) {
      newErrors.modelo = true;
    }
    if (!data.ano_modelo) {
      newErrors.ano_modelo = true;
    }
    if (!data.ano_fabricacao) {
      newErrors.ano_fabricacao = true;
    }
    if (!data.cor) {
      newErrors.cor = true;
    }
    if (!data.combustivel) {
      newErrors.combustivel = true;
    }

    setErrors(newErrors);

    // Retorna verdadeiro se todos os campos obrigatórios estiverem preenchidos
    return !Object.values(newErrors).some((error) => error);
  };

  const getFirstName = (frase) => {
    // Remova os espaços em branco no início e no final da frase
    frase = frase.trim();
    // Divida a frase em palavras usando o espaço como delimitador
    var palavras = frase.split(" ");
    // Pegue o primeiro elemento do array de palavras
    var primeiraPalavra = palavras[0];
    // Retorne a primeira palavra
    return primeiraPalavra;
  }

  const SubmitHandler = async (e) => {
    e.preventDefault();

    if (!validateFields()) {
      window.scrollTo({ top: 0, left: 0 });
      Swal.fire({
        icon: 'error',
        title: 'Preencha os campos obrigatórios',
        // text: 'Nome e Celular não podem ser vazios',
      });
      setLoading(false)
      return;
    }

    setLoading(true)
    const leadData = buildRequestData();

    // console.log(leadData)
    const result = await createLead(leadData);
    const leadId = result?.data?.data?.lead_id || null;
    if (leadId) {
      localStorage.setItem('___LeadID', leadId);
      localStorage.setItem('___LeadName', leadData.nome);
      localStorage.setItem('___LeadMobile', leadData.celular);
      localStorage.setItem('___LeadEmail', leadData.email);
      Swal.fire({
        icon: 'success',
        title: `Obrigado por enviar seu pedido de avaliação ${getFirstName(leadData.nome)}!`,
        text: `Em breve entraremos em contato, muito obrigado!`,
      });
      setData({...data, veiculo: "", marca: "", modelo: "", ano_modelo: "", ano_fabricacao: "", motivo: "", cor: "", combustivel: ""})
      setSelectedReason(null)
      setSelectedBrand(null)
      setSelectedVehicle(null)
      setSelectedColor(null)
      setSelectedFuel(null)
    } else {
      Swal.fire({
        icon: 'error',
        title: result.data.metadata.output.raw,
        text: result.data.metadata.reason,
      });
    }
    setLoading(false)
  };

  const handleSelect = (option, select) => {
    switch (select) {
      case "reason":
        setSelectedReason(option);
        setData({
          ...data,
          motivo: option === null ? "" : option.label,
        });
        break;
      case "brand":
        setSelectedBrand(option);
        setData({
          ...data,
          marca: option === null ? "" : option.label,
        });
        break;
      case "vehicle":
        setSelectedVehicle(option);
        setData({
          ...data,
          veiculo: option === null ? "" : option.label,
        });
        break;
      case "color":
        setSelectedColor(option);
        setData({
          ...data,
          cor: option === null ? "" : option.label,
        });
        break;
      case "fuel":
        setSelectedFuel(option);
        setData({
          ...data,
          combustivel: option === null ? "" : option.label,
        });
        break;
      default:
        return;
    }
  };

  return (
    <section className="gauto-contact-area section_70">
      <Container>
        <Row>
          <Col lg={12} md={12}>
            <h5>Preencha o formulário abaixo e tenha a melhor avaliação do mercado para seu veículo.</h5>
            <div className="contact-left mt-4">
              <h3>DADOS PESSOAIS</h3>
              {/* <h3>{t("contact_page.get_touch")}</h3> */}
              <form onSubmit={SubmitHandler}>
                <Row className="mb-3">
                  <Col md={5}>
                    <div className="single-contact-field">
                      <label htmlFor="name">Nome Completo*</label>
                      <input
                        id="name"
                        style={{ borderRadius: 10 }}
                        type="text"
                        placeholder='ex: Amanda Martins'
                        value={data.nome}
                        onChange={(e) => setData({...data, nome: e.target.value})}
                        // placeholder={t("contact_page.email")}
                      />
                      {errors.nome && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className="single-contact-field">
                      <label htmlFor="email">E-mail</label>
                      <input
                        style={{ borderRadius: 10 }}
                        id="email"
                        type="email"
                        placeholder='ex: exemplo@email.com'
                        value={data.email}
                        onChange={(e) => setData({...data, email: e.target.value})}
                        // placeholder={t("contact_page.email")}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="email">Celular*</label>
                      <input 
                        style={{ borderRadius: 10 }}
                        type="tel"
                        placeholder='ex: (99) 99999-9999'
                        value={(phoneMask(data.celular))}
                        onChange={(e) => setData({...data, celular: e.target.value})}
                      />
                      {errors.celular && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                </Row>
                <h3>DADOS DO VEÍCULO</h3>
                <Row className="mt-2">
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="reason">Motivo Avaliação*</label>
                      <Select
                        id="reason"
                        name="reason"
                        value={selectedReason}
                        onChange={(option) => handleSelect(option, "reason")}
                        options={optionsReasons}
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 98,
                            marginTop: 12
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                        placeholder="Selecione"
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                      />
                       {errors.motivo && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="type_vehicle">Tipo*</label>
                      <Select
                        id="type_vehicle"
                        name="type_vehicle"
                        value={selectedVehicle}
                        onChange={(option) => handleSelect(option, "vehicle")}
                        options={optionsVehicleType}
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 99,
                            marginTop: 12
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                        placeholder="Selecione"
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                      />
                       {errors.tipo && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="single-contact-field">
                      <label htmlFor="brand">Marca*</label>
                      <Select
                        id="brand"
                        name="brand"
                        value={selectedBrand}
                        onChange={(option) => handleSelect(option, "brand")}
                        options={optionsBrands}
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 99,
                            marginTop: 12
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                        placeholder="Selecione"
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                      />
                       {errors.marca && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="model">Modelo*</label>
                      <input
                        id="model"
                        style={{ borderRadius: 10 }}
                        type="text"
                        placeholder='ex: HB20S'
                        value={data.modelo}
                        onChange={(e) => setData({...data, modelo: e.target.value})}
                        // placeholder={t("contact_page.email")}
                      />
                       {errors.modelo && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2 mb-3">
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="year">Ano Modelo*</label>
                      <input
                        id="year"
                        style={{ borderRadius: 10 }}
                        type="number"
                        placeholder='ex: 2024'
                        value={data.ano_modelo}
                        onChange={(e) => setData({...data, ano_modelo: e.target.value})}
                        // placeholder={t("contact_page.email")}
                      />
                       {errors.ano_modelo && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="year">Ano Fabricação*</label>
                      <input
                        id="year"
                        style={{ borderRadius: 10 }}
                        type="number"
                        placeholder='ex: 2024'
                        value={data.ano_fabricacao}
                        onChange={(e) => setData({...data, ano_fabricacao: e.target.value})}
                        // placeholder={t("contact_page.email")}
                      />
                       {errors.ano_fabricacao && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="single-contact-field">
                      <label htmlFor="color">Cor*</label>
                      <Select
                        id="color"
                        name="color"
                        value={selectedColor}
                        onChange={(option) => handleSelect(option, "color")}
                        options={optionsColors}
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 98,
                            marginTop: 12
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                        placeholder="Selecione"
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                      />
                       {errors.cor && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="single-contact-field">
                      <label htmlFor="fuel">Combustível*</label>
                      <Select
                        id="fuel"
                        name="fuel"
                        value={selectedFuel}
                        onChange={(option) => handleSelect(option, "fuel")}
                        options={optionsFuel}
                        styles={{
                          container: (baseStyle, state) => ({
                            ...baseStyle,
                            zIndex: 98,
                            marginTop: 12
                          }),
                          input: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          indicatorsContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                          valueContainer: (baseStyle, state) =>({
                            ...baseStyle,
                            height: 40,
                          }),
                        }}
                        placeholder="Selecione"
                        noOptionsMessage={() => "Nenhum dado encontrado"}
                      />
                       {errors.combustivel && <div className="text-danger">Campo obrigatório</div>}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="single-contact-field">
                      <button type="submit" className="gauto-theme-btn">
                        {loading ? <div className="spinner-grow" role="status" style={{marginRight: 5, height: 15, width: 15}}></div> : <FaPaperPlane style={{marginRight: 5}}/> }
                        Enviar Avaliação
                        {/* <FaPaperPlane /> {t("contact_page.send")} */}
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FormAvaliation;
