import { createContext, useEffect, useState } from 'react';
import { getTraceInformation } from '../services/trace/requests';
import axios from 'axios';

export const LeadContext = createContext({});

export const LeadProvider = ({ children }) => {
  const [traceData, setTraceData] = useState(undefined);

  useEffect(() => {
    getTraceData();
  }, []);

  const getTraceData = async () => {
    const host = `${window.location.origin}`;
    // const traceinfo = await axios.get(`${host}/api/trace-information/`);
    // console.log(traceinfo);
    // setTraceData(traceinfo.data);
  };

  return (
    <LeadContext.Provider value={{ traceData }}>
      {children}
    </LeadContext.Provider>
  );
};
