import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaFacebookF,
  FaInstagram,
  // FaTwitter,
  // FaLinkedinIn,
  // FaSkype,
  FaPaperPlane,
} from "react-icons/fa";

// import img1 from "../../img/post-thumb-3.jpg";
// import img2 from "../../img/post-thumb-2.jpg";
// import img3 from "../../img/post-thumb-1.jpg";

import "./style.css";
import { CompanyContext } from "../../contexts/CompanyContext";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import { formatarCEP } from './../../utils/formatCep';
import { PageContext } from "../../contexts/PagesContext";
import { formatarCNPJ } from './../../utils/formatDocuments';

import LogoRevendaPro from '../../img/logo-rp.png';
import { getNews } from './../../services/news/requests';
import { dateFormatterLong } from './../../utils/convertDates';
import NoImage from '../../img/sem-foto.jpg';
import { getLogoFooter } from './../../services/files/requests';
import { phoneMask } from './../../utils/maskForPhone';
import useTraceId from "../../hooks/useTraceId";
import { LeadData } from "../../contexts/leadData";

import Swal from 'sweetalert2';
import { createLead } from './../../services/lead/requests';
import { getJobs } from "../../services/jobs/requests";

const Footer = () => {

  const {companyData} = useContext(CompanyContext);
  const {pagesData} = useContext(PageContext)
  const { t } = useTranslation();
  const currentDate = new Date();
  const [news, setNews] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [logoFooter, setLogoFooter] = useState(null);
  const [whatsapp, setWhatsapp] = useState("");
  const trace = useTraceId();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(companyData.id) {
      getLogo()
      getNewsList()
      getJobsList()
    }
    if(localStorage.getItem('___LeadID')){
      setWhatsapp(localStorage.getItem('___LeadMobile'))
    }
  },[companyData])

  const getLogo = async () => {
    const result = await getLogoFooter(companyData.id)
    if (result) {
      if (result.data.metadata.result >= 1) {
        setLogoFooter(result.data.data[0]);
      } else {
        setLogoFooter(null)
      }
    }
  };

  const getNewsList = async () => {
    const result = await getNews({}, companyData.id)
    if(result.data.metadata.result >= 1){
      setNews(result.data.data.filter(item => item.status === 'active'))
    }
  }

  const getJobsList = async () => {
    const result = await getJobs({}, companyData.id)
    if(result.data.metadata.result >= 1){
      setJobs(result.data.data.filter(item => item.status === 'active'))
    }
  }

  const onClick = (e) => {
    e.preventDefault();
  };

  const buildRequestData = () => {
    const url = window.location.href;

    const lead = new LeadData({
      nome: localStorage.getItem('___LeadName') ? localStorage.getItem('___LeadName') : "",
      celular: whatsapp ? whatsapp.replace(/[^0-9]/g, '') : "",
      cidade: companyData?.id_city,
      id_company: companyData?.id,
      estado: companyData?.id_uf,
      tipo: 1,
      source: 'newsletter',
      dominio: window.location.host,
      local: window.location.pathname,
      referrer: window.location.href,
      agent: navigator.userAgent,
      // ...traceData,
      trace_id: trace.traceId,
      url,
    });
    
    return lead.getData();
  };

  const getFirstName = (frase) => {
    // Remova os espaços em branco no início e no final da frase
    frase = frase.trim();
    // Divida a frase em palavras usando o espaço como delimitador
    var palavras = frase.split(" ");
    // Pegue o primeiro elemento do array de palavras
    var primeiraPalavra = palavras[0];
    // Retorne a primeira palavra
    return primeiraPalavra;
  }

  const SubmitHandler = async (e) => {
    e.preventDefault();

    if(whatsapp) {
      setLoading(true)
  
      const leadData = buildRequestData();
      const result = await createLead(leadData);
      const leadId = result?.data?.data?.lead_id || null;
      if (leadId) {
        localStorage.setItem('___LeadID', leadId);
        localStorage.setItem('___LeadMobile', leadData.celular);
        Swal.fire({
          icon: 'success',
          title: `Obrigado por se cadastrar ${leadData.nome ? `, ${getFirstName(leadData.nome)}` : ""}! 🎉`,
          text: `Agora você está conectado com as últimas novidades! 📱✨`,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: result.data.metadata.output.raw,
          text: result.data.metadata.reason,
        });
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Celular não pode ser vazio',
        // text: 'Nome e Celular não podem ser vazios',
      });
    }

    setLoading(false)
  };


  return (
    <footer className="gauto-footer-area">
      <div className="footer-top-area" style={{background:' url(../../img/page-bkg.jpg) no-repeat scroll 0 0 / cover'}}>
        <Container>
          <Row>
            <Col lg={4}>
              <div className="single-footer">
                <div className="footer-logo">
                  <Link to="/">
                    <img src={logoFooter ? logoFooter.file : LogoRevendaPro} alt="footer-logo" style={{height: 100}}/>
                  </Link>
                </div>
                <p>
                  {companyData?.description}
                </p>
                {/* <div className="footer-address">
                  <h3>{t("footer.head_office")}</h3>
                  <p>
                    125 Big fella St. Road, <span>New York, Hi 5654775</span>
                  </p>
                  <ul>
                    <li>{t("footer.phone")}: 326487652 </li>
                    <li>{t("footer.email")}: example@mail.com</li>
                    <li>{t("footer.office_time")}: 9AM- 4PM</li>
                  </ul>
                </div> */}
              </div>
              <div className="single-footer quick_links">
                {/* <h3>Contatos</h3> */}
                {/* <h3>{t("footer.head_office")}</h3> */}
                <p>
                  {companyData?.endereco && companyData?.number
                    ? `
                        ${companyData?.endereco || ''}${
                        companyData?.endereco && companyData?.number
                          ? `, ${companyData?.number}`
                          : ''
                      }
                        ${companyData?.dsitrict || ''}${
                        companyData?.endereco && companyData?.district
                          ? `, ${companyData?.district}`
                          : ''
                      }
                        ${
                          companyData?.endereco &&
                          companyData?.city &&
                          companyData?.uf
                            ? ` - ${companyData?.city}/${companyData?.uf}`
                            : ''
                        }
                      `
                    : companyData?.city && companyData?.uf
                    ? `${companyData?.city}/${companyData?.uf}`
                    : ''}
                    {companyData?.cep ? ` - ${formatarCEP(companyData.cep)}` : ""}
                </p>
                <ul>
                  <li>Whatsapp: {formatPhoneNumber(companyData?.whatsapp_number)} </li>
                  <li>E-mail: {companyData?.email}</li>
                  {/* <li>{t("footer.office_time")}: 9AM- 4PM</li> */}
                  {/* <li>{t("footer.phone")}: 326487652 </li>
                  <li>{t("footer.email")}: example@mail.com</li>
                  <li>{t("footer.office_time")}: 9AM- 4PM</li> */}
                </ul>
              </div>
              <div className="footer-social" style={{display: 'flex', justifyContent: 'flex-start', marginTop: 10}}>
                <ul>
                  {companyData?.facebook ?
                    <li>
                      <Link to={companyData.facebook} onClick={onClick} target="_blank" rel="noopener noreferrer">
                        <FaFacebookF />
                      </Link>
                    </li>
                  : <></>}
                  {companyData?.instagram ?
                    <li>
                      <Link to={companyData.instagram} onClick={onClick} target="_blank" rel="noopener noreferrer">
                        <FaInstagram />
                      </Link>
                    </li>
                  : <></>}
                  {/* <li>
                    <Link to="/" onClick={onClick} target="_blank" rel="noopener noreferrer">
                      <FaTwitter />
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link to="/" onClick={onClick} target="_blank" rel="noopener noreferrer">
                      <FaLinkedinIn />
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link to="/" onClick={onClick} target="_blank" rel="noopener noreferrer">
                      <FaSkype />
                    </Link>
                  </li> */}
                </ul>
              </div>
            </Col>
            <Col lg={4}>
              <div className="single-footer quick_links">
                <h3>Acesso Rápido</h3>
                {/* <h3>{t("footer.quick_links")}</h3> */}
                <ul className="quick-links">
                  {/* <li>
                    <Link to="/about" onClick={onClick}>
                      {" "}
                      Quem Somos
                    </Link>
                  </li> */}
                  {pagesData.length > 0 ?
                    pagesData.filter(item => item.show_in == 'both' || item.show_in == 'footer').map((page) => (
                      <li key={page.id}>
                        <Link to={`/${page.slug}`}>{page.title}</Link>
                      </li>
                    ))
                  : <></>}
                  {jobs.length > 0 ? 
                    <li>
                      <Link to="/vagas">
                        Trabalhe Conosco
                      </Link>
                    </li> 
                  : <></>}
                  {/* <li>
                    <Link to="/" onClick={onClick}>
                     {t("footer.testimonials")}
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link to="/contato">
                      Contato
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link to="/" onClick={onClick}>
                      Política de Privacidade
                    </Link>
                  </li> */}
                </ul>
                {/* <ul className="quick-links"> */}
                  {/* <li>
                    <Link to="/" onClick={onClick}>
                      {t("footer.our_service")}
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link to="/" onClick={onClick}>
                      {t("footer.case_studies")}
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link to="/" onClick={onClick}>
                      {t("footer.latest_news")}
                    </Link>
                  </li> */}
                {/* </ul> */}
              </div>
              <div className="single-footer newsletter_box">
                <h3>Receba Novidades</h3>
                {/* <h3>{t("footer.newsletter")}</h3> */}
                <form onSubmit={SubmitHandler}>
                  <input type="tel" placeholder="(99) 99999-9999" value={phoneMask(whatsapp)} onChange={(e) => setWhatsapp(e.target.value)}/>
                  <button type="submit">
                    {loading ? <div className="spinner-grow" role="status" style={{height: 10, width: 10}}></div>
                    : <FaPaperPlane />}
                  </button>
                </form>
              </div>
            </Col>
            {news.length > 0 ?
              <Col lg={4}>
                <div className="single-footer">
                  <h3>Postagens Recentes</h3>
                  <ul>
                   { news.slice(0,3).map(item => (
                      <li key={item.id}>
                        <div className="single-footer-post">
                          <div className="footer-post-image">
                            <Link to={`/detalhe-blog/${item.slug}`}>
                              <img className="logo_footer" src={item.media.length > 0 ? item.media[0].file : NoImage} alt="footer post" />
                            </Link>
                          </div>
                          <div className="footer-post-text">
                            <h3>
                              <Link to={`/detalhe-blog/${item.slug}`}>
                                {item.title}
                              </Link>
                            </h3>
                            <p>Postado em: {dateFormatterLong.format(new Date(item.created_at))}</p>
                          </div>
                        </div>
                      </li>
                    ))}
                    
                    {/* <li>
                      <div className="single-footer-post">
                        <div className="footer-post-image">
                          <Link to="/blog-single">
                            <img src={img2} alt="footer post" />
                          </Link>
                        </div>
                        <div className="footer-post-text">
                          <h3>
                            <Link to="/blog-single">
                              Revealed: How to set goals for you and your team
                            </Link>
                          </h3>
                          <p>Posted on: Jan 12, 2019</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="single-footer-post">
                        <div className="footer-post-image">
                          <Link to="/blog-single">
                            <img src={img3} alt="footer post" />
                          </Link>
                        </div>
                        <div className="footer-post-text">
                          <h3>
                            <Link to="/blog-single">
                              apartment in the sky love three boys of his own.
                            </Link>
                          </h3>
                          <p>Posted on: Jan 12, 2019</p>
                        </div>
                      </div>
                    </li> */}
                  </ul>
                </div>
              </Col>
            : <></>}
          </Row>
        </Container>
      </div>
      <div className="footer-bottom-area">
        <Container>
          <Row>
            <Col md={12}>
              <a href={`${process.env.REACT_APP_FOOTER_LINK}${window.location.host}`} target="_blank" rel="noopener noreferrer">
                <div className="copyright" style={{display: 'flex', flexDirection:'column', alignItems: 'center'}}>
                  <p className="copyright">&copy; {currentDate.getFullYear()} {companyData?.social_name} - Todos os direitos reservados.</p>
                  {companyData?.cnpj ? 
                      <p className="copyright">Inscrito sob o CNPJ - {formatarCNPJ(companyData.cnpj)}</p>
                  : <></>}
                  <img className="logo_product" src={LogoRevendaPro} style={{height: 50}}/>
                </div>
              </a>
            </Col>
            {/* <Col md={6}>
              <div className="footer-social">
                <ul>
                  {companyData?.facebook ?
                    <li>
                      <Link to={companyData.facebook} onClick={onClick} target="_blank" rel="noopener noreferrer">
                        <FaFacebookF />
                      </Link>
                    </li>
                  : <></>}
                  {companyData?.instagram ?
                    <li>
                      <Link to={companyData.instagram} onClick={onClick} target="_blank" rel="noopener noreferrer">
                        <FaInstagram />
                      </Link>
                    </li>
                  : <></>}
                </ul>
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
